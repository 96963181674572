/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Layout, StaticSEO } from '@core';
import { AboutUsPage } from '@composite';

const AboutUs = () => (
  <Layout>
    <StaticSEO pageId="about-us" />
    <AboutUsPage />
  </Layout>
);

export default AboutUs;
